import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import ReactHtmlParser from "react-html-parser";

const Thirdsec = () => {
  const [data, setData] = useState([]);

  const fetchData = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND}/api/get-homepage`
    );
    const result = await response.json();
    setData(result);
  };
  useEffect(() => {
    fetchData();
  }, []);

  const [outsource, setOutsource] = useState([]);

  const fetchOutsource = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND}/api/get-outsources`
    );
    const result = await response.json();
    setOutsource(result);
  };
  useEffect(() => {
    fetchOutsource();
  }, []);

  return (
    <section className="third-sec">
      <Container>
        <Row>
          {data.map((row, index) => (
            <>
              <h2>{row.buttontwo}</h2>
              <p>{row.descone}</p>
            </>
          ))}
          {outsource.map((row, index) => (
            <Col md={4} lg={3} xs={12}>
              <div class="flip-card">
                <div class="flip-card-inner">
                  <div class="flip-card-front">
                    <h3>{row.outsourcenumber}</h3>
                  </div>
                  <div class="flip-card-back">
                    <div className="flip-card-back-bg">
                      <h1>{row.outsourcetitle}</h1>
                      <p>{ReactHtmlParser(row.outsourcedesc)}</p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default Thirdsec;

{
  /* <section className='third-sec-parent'>
            <Container fluid>
            {data.map((row, index) => (

                <Row className='third-sec'>
                    <h1 className='text-center'>{row.headingthree}</h1>
                </Row>
            ))}
                <Row className='third-sec py-5'>
                {outsource.map((row, index) => (

                    <Col md={4}>
                    <Card >
                        <Card.Header className='first-card'><span></span><br/></Card.Header>
                        <Card.Body>
                        <Card.Title className='c-t'></Card.Title>
                        <Card.Text className='c-p'>
                        </Card.Text>
                        </Card.Body>
                    </Card>
                    </Col>
                                ))}

                    
                </Row>
            </Container>
        </section> */
}
