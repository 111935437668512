import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import img from '../../assets/compliance-graphic.png';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import b1 from '../../assets/b1.webp'
import b2 from '../../assets/b2.webp'
import user from '../../assets/Cody_Foster.webp'
import '../Home/Home.css'
import './blog.css'

const Intro = () => {
  return (
        <>
       <section className="blogintrosec">
      <Container >
        <Row className='py-5'>
          <Col>
            <h1 className=' blog-intro-title'>FinTech Law Blog</h1>
            <p>FinTech Blog offers a range of resources about the latest developments in <br/> fintech, regtech, digital assets, and regulatory<br/> compliance.</p>
          </Col>
          
        </Row>
    
      </Container>
    </section>
        
        </>
    )
}

export default Intro