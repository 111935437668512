import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';

import { Col, Container, Row } from 'react-bootstrap'
import Button from 'react-bootstrap/Button';
import './eventdetail.css'
import '../ReFunds/ReFunds.css'
import eventdetail from '../../assets/event-detail.png'
const Intro = () => {
    const styles= {
        float:"right"
    }
    const [eventstitle, setName] = useState('');
    const [description, setDesc] = useState('');
    const [excerpt, setExcerpt] = useState('');
    const [youtubevideolink, setyoutubevideolink] = useState('');
    const [file, setFile] = useState();
    const [fileName, setFileName] = useState("");
    const [error, SetError]=useState("");
    const params = useParams();

    const getTeamDetails = async () => {
        console.warn(params);
        let result = await fetch(`${process.env.REACT_APP_BACKEND}/api/update-webinars/${params.id}`);
        result = await result.json();
        setName(result.eventstitle);
        setDesc(result.description);
        setExcerpt(result.excerpt);
        setFileName(result.document)
        setyoutubevideolink(result.youtubevideolink)
      };
    
      useEffect(() => {
        getTeamDetails();
      }, []);
  return (
        <section className='event-detail-sec-1'>
            <Container fluid>
                <Row>
                    <Col md={3}>
                    </Col>
                    <Col >
                        <h2 className='text-center'>{eventstitle}</h2>
                        <p className='sub-head-event-detail text-center'>{excerpt}</p>

                        <p className='text-center'>
                        {ReactHtmlParser(description)}
                        </p>
                    </Col>
                    <Col md={3}>
                    </Col>
                    
                </Row>
            </Container>
        </section>

    )
}

export default Intro