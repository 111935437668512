import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";

import "../ReFunds/ReFunds.css";

const Intro = () => {
  const [partner, setpartner] = useState([]);

  const fetchpartnerpage = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND}/api/get-eventspage`
    );
    const result = await response.json();
    setpartner(result);
  };
  useEffect(() => {
    fetchpartnerpage();
  }, []);

  return (
    <section className="blogintrosec">
      <Container>
        <Row className="py-5">
          <Col>
            {partner.map((row, index) => (
              <>
                <h1 className=" blog-intro-title">{row.heading}</h1>
                <p>{row.subheading}</p>
              </>
            ))}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Intro;

{
  /* <section className='refunds-service-sec-1'>
<Container fluid>
    <Row>
        <Col>
        {partner.map((row, index) => (

        <>
            <h1>{row.heading}</h1>
            <p>{row.subheading}N
                </p>
             <Button variant="outline-light my-5" classame="blue-btn refundbtn">HELP ME WITH FUNDS COMPLIANCE</Button> 
</>
        ))}
        </Col>
    </Row>
</Container>
</section> */
}
