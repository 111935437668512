import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card";
import { Form, Button } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroller";
import "./blog.css";
import "../Home/Home.css";

const PAGE_SIZE = 6;

const Secondsec = () => {
  const [data, setData] = useState([]);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async (pageNum) => {
    try {
      setIsLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/api/get-posts?page=${pageNum}`
      );
      const result = await response.json();

      setData((prev) => [...prev, ...result]);
      setHasMoreData(result.length === PAGE_SIZE);
    } catch (err) {
      setHasMoreData(false);
    } finally {
      setIsLoading(false);
    }
  };

  const styles = {
    width: "100%",
  };

  const renderLoader = () => (
    <div key={data.length}>
      <div
        class="spinner-border"
        role="status"
        style={{ color: "white", display: "block", margin: "auto" }}
      >
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  );

  return (
    <>
      <section className="blog-page-second-sec">
        <Container>
          <Row>
            <Col md={12}>
              <Row>
                <Link className="mx-auto text-center mb-5" to={"/contact"}>
                  <Button>Contact FinTech Law!</Button>
                </Link>
              </Row>
              <div style={{ height: "100%" }}>
                {!data.length && renderLoader()}
                <InfiniteScroll
                  pageStart={0}
                  dataLength={data.length}
                  loadMore={fetchData}
                  initialLoad={true}
                  hasMore={hasMoreData && !isLoading}
                  loader={renderLoader()}
                  threshold={450}
                >
                  <Row>
                    {data.map((row, index) => (
                      <Col md={4} key={index} className="blog1stcol">
                        <Link to={"/blog/" + row.slug} className="blog-link">
                          <Card className="blog-box">
                            <Card.Img
                              variant="top"
                              src={
                                "https://storage.googleapis.com/fintechlegal/posts/" +
                                row.document
                              }
                            />
                            <Card.Body className="blog-body">
                              <Card.Title className="blog-title">
                                {row.posttitle}
                              </Card.Title>
                              <Row style={styles}>
                                <Col className="blog-tag-col">
                                  {row.tags.split(",").map((tag, tagIndex) => (
                                    <div key={tagIndex}>
                                      <Button
                                        variant="outline-light"
                                        className="blogtag-btn"
                                      >
                                        {tag.trim()}
                                      </Button>
                                    </div>
                                  ))}
                                </Col>
                                {row.selectedTeam ? (
                                  <p className="blog-date">
                                    {row.selectedTeam.membername} -{" "}
                                    {new Date(row.date).toLocaleDateString()}
                                  </p>
                                ) : (
                                  <>
                                    <h6 className="blogpage-author">
                                      Author Name
                                    </h6>
                                    <p className="blogpage-author-position">
                                      Author Position
                                    </p>
                                  </>
                                )}
                              </Row>
                            </Card.Body>
                          </Card>
                        </Link>
                      </Col>
                    ))}
                  </Row>
                </InfiniteScroll>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="fourth-sec">
        <Container>
          <Row>
            <Col>
              {/* <Form>
                <Row>
                  <Col>
                    <Form.Group controlId="formEmail">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control type="email" placeholder="Enter your email" style={{ height: '50px' }} />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="formEmail">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control type="email" placeholder="Enter your email" style={{ height: '50px' }} />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="formEmail">
                      <Form.Label>Email</Form.Label>
                      <Form.Control type="email" placeholder="Enter your email" style={{ height: '50px' }} />
                    </Form.Group>
                  </Col>
                </Row>
                <Button variant="primary" type="submit" className="my-3 newsletter-btn">
                  Newsletter Signup
                </Button>
              </Form> */}

              <iframe
                src="https://forms.zohopublic.com/codyfoster/form/ComplianceUpdatesFTL/formperma/ra3WF4tQLb1XkKAxk3fkkEgt5A2zN4AqV5Qhp_V3mEo?zf_rszfm=1"
                width="100%"
                height="450px"
              />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Secondsec;
