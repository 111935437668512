import React,{useState,useEffect} from 'react'
import { Container , Row , Col } from 'react-bootstrap'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import blog from '../../assets/blog.webp'
import './resources.css'
const Intro = () => {

    const [partner, setpartner] = useState([]);

    const fetchpartnerpage = async () => {
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/get-reourcespage`);
        const result = await response.json();
        setpartner(result);
      };
      useEffect(() => {
          fetchpartnerpage();
      }, []);
  return (
        
    <>
    
    <section className='blogintrosec'>
            <Container>
                <Row>
                    <Col>
                    {partner.map((row, index) => (

                        <>
                        <h1 className='newsletter-resource-heading'>{row.heading}</h1>
                        <p>
                            {row.subheading}
                         </p>
                        {/* <Button variant="outline-light my-5" className="blue-btn refundbtn">HELP ME WITH FUNDS COMPLIANCE</Button> */}
</>
                    ))}
                    </Col>
                </Row>
            </Container>
        </section>
    
    </>
      
    )
}

export default Intro

