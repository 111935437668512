import React, { useState,useEffect,useRef } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import img from '../../assets/compliance-graphic.png';
import Button from 'react-bootstrap/Button';
import '../Home/Home.css'
import './contact.css'

const Intro = () => {
  const [data, setData] = useState([]);

    const fetchData = async () => {
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/get-contactpage`);
        const result = await response.json();
        setData(result);
      };
      useEffect(() => {
        fetchData();
      }, []);

  return (
        <>
       <section className="contactintrosec">
      <Container>
        <Row className='py-5'>
          <Col>

            <h1 className=' contact-intro-title'>Terms of Service</h1>
          {/* <p className=' contact-intro-subtext'>In our line of work, communication and relationships matter most. Please send us a message and we'll be in touch soon. We look forward to hearing from you.</p> */}
          </Col>
          
        </Row>
      </Container>
    </section>
        
        </>
    )
}

export default Intro