import React from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import Sidebar from "../../Components/Sidebar";
import NewNewsletter from "./NewNewsletter";

const index = () => {
  return (
    <React.Fragment>
      <div className="wrapper">
        <Header />
        <Sidebar />
        <NewNewsletter />
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default index;
