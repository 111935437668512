import React, { useState, useEffect, useRef } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { store } from "./store/store";
import { Provider } from "react-redux";
import "./App.css";

import Home from "./Pages/Home/index";
import Services from "./Pages/Services/index";
import Resources from "./Pages/Resources/index";
import About from "./Pages/About/index";
import Tools from "./Pages/Tools/index";
import Contact from "./Pages/Contact/index";
import RegisteredFunds from "./Pages/ReFunds/index";

import Blog from "./Pages/Blog/index";
import Events from "./Pages/Events/index";
import Partnerships from "./Pages/Partnerships/index";
import InfographicsResources from "./Pages/Infographics-Resources/index";
import NewsletterResources from "./Pages/Newsletter-Resources/index";

import PrivateRoute from "./Dashboard/Components/PrivateRoute";

import Dashboard from "./Dashboard/Home/index";
import Login from "./Dashboard/Login/index";
import ContactEntries from "./Dashboard/Contact/index";
import NewsletterEntries from "./Dashboard/Newsletter/index";
import TeamData from "./Dashboard/Team/data/index";
import CollaboratorsData from "./Dashboard/Collaborators/data/index";
import NewTeam from "./Dashboard/Team/index";
import NewCollaborator from "./Dashboard/Collaborators/index";
import UpdateTeam from "./Dashboard/Team/update/index";
import UpdateCollaborators from "./Dashboard/Collaborators/update/index";
import PartnerData from "./Dashboard/Partnerships/data/index";
import NewPartner from "./Dashboard/Partnerships/index";
import MembershipsData from "./Dashboard/Memberships/data/index";
import NewMemberships from "./Dashboard/Memberships/index";
import NewNewsletter from "./Dashboard/Resources/Newsletter/index";
import DataNewsletter from "./Dashboard/Resources/Newsletter/Data/index";
import DataInfographics from "./Dashboard/Resources/Infographics/Data/index";
import DataBlog from "./Dashboard/Blogs/data/index";
import NewBlog from "./Dashboard/Blogs/index";
import BlogDetail from "./Pages/BlogDetail/index";
import DataEvents from "./Dashboard/Events/data/index";
import NewEvents from "./Dashboard/Events/index";
import EventsDetail from "./Pages/Events-detail/index";
import WebinarData from "./Dashboard/Webinars/data/index";
import Newebinars from "./Dashboard/Webinars/index";
import WebinarDetail from "./Pages/webinar-detail/index";
import PagesList from "./Dashboard/Pages/data/index";
import NewHomePage from "./Dashboard/Pages/index";
import UpdateHomePage from "./Dashboard/Pages/update/index";
import UpdateservicesPage from "./Dashboard/Pages/updateservices/index";
import Updateregisteredfundspage from "./Dashboard/Pages/update-registeredfunds/index";
import UpdateMockSecExam from "./Dashboard/Pages/update-mocksecexam/index";
import UpdateRiaRegisteration from "./Dashboard/Pages/update-riaregisteration/index";
import UpdateCcomplainceNewsletter from "./Dashboard/Pages/update-compliancenewsletter/index";
import UpdateEvents from "./Dashboard/Pages/Update-Events/index";
import UpdateResources from "./Dashboard/Pages/update-resourcespage/index";
import UpdateAbout from "./Dashboard/Pages/updateAboutpage/index";
import UpdatePartners from "./Dashboard/Pages/update-partnerspage/index";
import UpdateContact from "./Dashboard/Pages/update-Contact/index";
import PackagesData from "./Dashboard/Packages/data/index";
import PackagesNew from "./Dashboard/Packages/index";
import OutsourceNew from "./Dashboard/Outsource/index";
import OutsourceData from "./Dashboard/Outsource/data/index";
import AdditionalResourcesNew from "./Dashboard/Additional-Resources/index";
import AdditionalResourcesData from "./Dashboard/Additional-Resources/data/index";
import UpdateServices from "./Dashboard/Packages/update/index";
import UpdateOutSource from "./Dashboard/Outsource/update/index";
import UpdateEventsService from "./Dashboard/Events/update/index";
import UpdateBlogPost from "./Dashboard/Blogs/update/index";
import Privacy from "./Pages/Privacy/index";
import Terms from "./Pages/Terms/index";
import Infographics from "./Dashboard/Resources/Infographics/index";
import NewMemberShips from "./Dashboard/Memberships/NewMemberships";
import NewWebinars from "./Dashboard/Webinars/NewWebinars";

function App() {
  const auth = localStorage.getItem("user");

  const [cart, setCart] = useState([]);
  useEffect(() => {
    if (
      localStorage.getItem("cart") != null &&
      localStorage.getItem("cart") != "null"
    ) {
      setCart(JSON.parse(localStorage.getItem("cart")).cart);
    }
  }, []);

  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/services" element={<Services />} />
          <Route exact path="/resources" element={<Resources />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/tools" element={<Tools />} />
          <Route exact path="/partnerships" element={<Partnerships />} />
          <Route exact path="/privacy-policy" element={<Privacy />} />
          <Route exact path="/terms-of-service" element={<Terms />} />

          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/services/:slug" element={<RegisteredFunds />} />
          {/* <Route exact path="/services/mock-sec-exam-services" element={<MOCKSECExam/>}/>
            <Route exact path="/services/ria-registration-services" element={<RIAServices/>}/> */}
          <Route exact path="/blog" element={<Blog />} />
          <Route exact path="/blog/:slug" element={<BlogDetail />} />
          <Route
            exact
            path="/resources/compliance-newsletter"
            element={<NewsletterResources />}
          />
          <Route
            exact
            path="/resources/infographics"
            element={<InfographicsResources />}
          />
          <Route exact path="/events-detail/:id" element={<EventsDetail />} />
          <Route exact path="/webinar-detail/:id" element={<WebinarDetail />} />
          <Route exact path="/events" element={<Events />} />
          <Route exact path="/login" element={<Login />} />
          <Route
            exact
            path="/dashboard"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route exact path="/contact-entries" element={<ContactEntries />} />
          <Route
            exact
            path="/newsletter-entries"
            element={<NewsletterEntries />}
          />
          <Route exact path="/team-list" element={<TeamData />} />
          <Route
            exact
            path="/collaborators"
            element={
              <PrivateRoute>
                <CollaboratorsData />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/add-team"
            element={
              <PrivateRoute>
                <NewTeam />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/add-collaborator"
            element={
              <PrivateRoute>
                <NewCollaborator />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/updateteam/:id"
            element={
              <PrivateRoute>
                <UpdateTeam />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/update-collaborator/:id"
            element={
              <PrivateRoute>
                <UpdateCollaborators />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/partner-list"
            element={
              <PrivateRoute>
                <PartnerData />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/add-partner"
            element={
              <PrivateRoute>
                <NewPartner />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/add-memberships"
            element={
              <PrivateRoute>
                <NewMemberships />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/memberships-list"
            element={
              <PrivateRoute>
                <MembershipsData />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/resources-newsletter-list"
            element={
              <PrivateRoute>
                <DataNewsletter />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/resources-infographics-list"
            element={
              <PrivateRoute>
                <DataInfographics />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/new-resources-newsletter"
            element={
              <PrivateRoute>
                <NewNewsletter />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/new-resources-infographics"
            element={
              <PrivateRoute>
                <Infographics />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/post-new"
            element={
              <PrivateRoute>
                <NewBlog />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/post-list"
            element={
              <PrivateRoute>
                <DataBlog />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/events-list"
            element={
              <PrivateRoute>
                <DataEvents />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/new-events"
            element={
              <PrivateRoute>
                <NewEvents />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/new-webinars"
            element={
              <PrivateRoute>
                <Newebinars />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/webinats-list"
            element={
              <PrivateRoute>
                <WebinarData />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/pages-list"
            element={
              <PrivateRoute>
                <PagesList />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/new-homepage"
            element={
              <PrivateRoute>
                <NewHomePage />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/update-homepage/:id"
            element={
              <PrivateRoute>
                <UpdateHomePage />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/update-servicespage/:id"
            element={
              <PrivateRoute>
                <UpdateservicesPage />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/update-registeredfunds/:id"
            element={
              <PrivateRoute>
                <Updateregisteredfundspage />{" "}
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/update-mocksecexam/:id"
            element={
              <PrivateRoute>
                <UpdateMockSecExam />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/update-riaregistrationservices/:id"
            element={
              <PrivateRoute>
                <UpdateRiaRegisteration />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/update-compliancenewsletterpage/:id"
            element={
              <PrivateRoute>
                <UpdateCcomplainceNewsletter />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/update-eventspage/:id"
            element={
              <PrivateRoute>
                <UpdateEvents />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/update-reourcespage/:id"
            element={
              <PrivateRoute>
                <UpdateResources />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/update-aboutpage/:id"
            element={
              <PrivateRoute>
                <UpdateAbout />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/update-partnerpage/:id"
            element={
              <PrivateRoute>
                <UpdatePartners />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/update-contactpage/:id"
            element={
              <PrivateRoute>
                <UpdateContact />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/packages-list"
            element={
              <PrivateRoute>
                <PackagesData />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/packages-new"
            element={
              <PrivateRoute>
                <PackagesNew />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/outsource-new"
            element={
              <PrivateRoute>
                <OutsourceNew />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/outsource-data"
            element={
              <PrivateRoute>
                <OutsourceData />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/additionalresources-new"
            element={
              <PrivateRoute>
                <AdditionalResourcesNew />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/additionalresources-list"
            element={
              <PrivateRoute>
                <AdditionalResourcesData />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/update-services/:slug"
            element={
              <PrivateRoute>
                <UpdateServices />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/update-outsource/:id"
            element={
              <PrivateRoute>
                <UpdateOutSource />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/update-events/:id"
            element={
              <PrivateRoute>
                <UpdateEventsService />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/update-blogpost/:slug"
            element={
              <PrivateRoute>
                <UpdateBlogPost />
              </PrivateRoute>
            }
          />
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
