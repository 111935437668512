import React from 'react'
import Footer from '../../Components/Footer/Footer'
import Header from '../../Components/Header/Header'
import Intro from './Intro'
import Secondsec from './Secondsec'
import Fourthsec from './Fourthsec'
import ThirdSec from './ThirdSec'
import { Helmet } from 'react-helmet'
const index = () => {
  return (
    <>
    <Helmet>
            <title>
            FinTech Law Services | Securites Law Services
            </title>
            <meta name="description" content="FinTech Law provides dedicated law services to a variety of clients and areas including registered investment advisers, private funds, and startups.
"/>
        </Helmet>
    <Header/>
    <React.Fragment>
    <Intro/>
    <Secondsec/>
    <ThirdSec/>
    {/* <Fourthsec/> */}
    </React.Fragment>
    <Footer/>
    </>
    )
}

export default index