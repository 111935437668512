import { Container, Row, Col } from "react-bootstrap";

const SubscribeForm = () => {
  return (
    <section className="fourth-sec" id="subscribe-form">
      <Container>
        <Row>
          <Col>
            <iframe
              src="https://forms.zohopublic.com/codyfoster/form/ComplianceUpdatesFTL/formperma/ra3WF4tQLb1XkKAxk3fkkEgt5A2zN4AqV5Qhp_V3mEo?zf_rszfm=1"
              width="100%"
              height="650px"
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default SubscribeForm;
