import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./newsletter.css";
import "../ReFunds/ReFunds.css";

const Intro = () => {
  const [partner, setpartner] = useState([]);

  const fetchpartnerpage = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND}/api/get-compliancenewsletterpage`
    );
    const result = await response.json();
    setpartner(result);
  };
  useEffect(() => {
    fetchpartnerpage();
  }, []);

  return (
    <section className="blogintrosec">
      <Container>
        <Row>
          <Col>
            {partner.map((row) => (
              <>
                <h1 className="newsletter-resource-heading">{row.heading}</h1>
                <p>{row.subheading}</p>
              </>
            ))}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Intro;
