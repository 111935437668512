import React from "react";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import Sidebar from "../Components/Sidebar";
import NewCollaborator from "./Collaborators";

const index = () => {
  return (
    <React.Fragment>
      <div className="wrapper">
        <Header />
        <Sidebar />
        <NewCollaborator />
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default index;
