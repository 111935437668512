import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Alert } from "@mui/material";
import { TextField, Button, Grid, makeStyles } from "@material-ui/core";
import "../index.css";
import { request } from "../../helpers/fetch-wrapper";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "100%",
    },
  },
}));

const NewPartner = () => {
  const classes = useStyles();
  const [name, setName] = useState("");
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState("");
  const [error, setError] = useState("");

  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.user.info);

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    submitProduct(); // Call the submitProduct function here
  };

  const submitProduct = async () => {
    const userID = currentUser._id;
    const baseUrl = `${process.env.REACT_APP_BACKEND}/api/add-partnership`;
    const data = {
      name: name,
      userID: userID,
    };
    const formData = new FormData();
    for (let name in data) {
      formData.append(name, data[name]);
    }
    formData.append("file", file);
    if (!file) {
      setError("Please upload an image.");
      return;
    } else {
      setError("");
    }
    const result = await request({
      method: "post",
      url: baseUrl,
      data: formData,
    });

    if (result) {
      console.log(result);
      navigate("/partner-list");
    }
  };

  const saveFile = (e) => {
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };
  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Add Partners</h1>
              </div>
              {/* /.col */}
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right"></ol>
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container-fluid */}
        </div>

        <div className="adminform-background px-3 mx-3 py-3">
          <form className={classes.root} onSubmit={handleSubmit}>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <TextField
                  required
                  label="Partner Url"
                  value={name}
                  onChange={handleNameChange}
                />
              </Grid>
              <Grid item xs={6}>
                <input
                  accept="image/*"
                  id="thumbnail-upload"
                  type="file"
                  onChange={saveFile}
                  style={{ display: "none" }}
                />
                <label htmlFor="thumbnail-upload">
                  <Button variant="contained" color="primary" component="span">
                    Upload Partner Thumbnail
                  </Button>
                </label>
              </Grid>
              <Grid item xs={12}>
                {error && (
                  <Alert severity="error" onClose={() => setError("")}>
                    {error}
                  </Alert>
                )}
              </Grid>
              <Grid item xs={12}>
                <Button variant="contained" color="primary" type="submit">
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </div>
    </>
  );
};

export default NewPartner;
