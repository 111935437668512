import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { Form, Button } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import { request } from "../../helpers/fetch-wrapper";

const Secondsec = () => {
  const [fname, setFirstName] = useState("");
  const [lname, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [company, setCompany] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async () => {
    // const userID = JSON.parse(localStorage.getItem('user')).result._id;
    const baseUrl = `${process.env.REACT_APP_BACKEND}/api/contact`;
    const data = {
      fname: fname,
      lname: lname,
      email: email,
      jobTitle: jobTitle,
      company: company,
      message: message,
    };

    const result = await request({
      method: "post",
      url: baseUrl,
      data: data,
    });

    if (result) {
      console.log(result);
      navigate("/contact");
    }
  };
  const styles = {
    width: "700px",
    height: "800px",
  };
  const [data, setData] = useState([]);

  const fetchData = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND}/api/get-contactpage`
    );
    const result = await response.json();
    setData(result);
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <section className="second-sec-contact">
        <Container>
          <Row>
            <Col className="contact-first-col">
              <h2>Schedule A Consultation?</h2>

              {data.map((row, index) => (
                <>
                  <p>{ReactHtmlParser(row.desc)}</p>
                </>
              ))}
              <a
                className="nounderline"
                href="https://calendly.com/fintechlaw/30min"
                target="_blank"
              >
                <Button>Book A Meeting</Button>
              </a>
            </Col>
            <Col className="contact-second-col">
              <h2 className="pl-4">Contact Us</h2>
              {/* <Form>
                    <Form.Group controlId="formFullName">
                        <Form.Label>Full Name</Form.Label>
                        <Form.Control type="text" name="fullName"  />
                    </Form.Group>
                    <Form.Group controlId="formCompanyName">
                        <Form.Label>Company Name</Form.Label>
                        <Form.Control type="text" name="companyName" />
                    </Form.Group>
                    <Form.Group controlId="formEmail">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control type="email" name="email" />
                    </Form.Group>
                    <Form.Group controlId="formMessage">
                        <Form.Label>Message</Form.Label>
                        <Form.Control as="textarea" name="message"/>
                    </Form.Group>
                    <Button variant="primary" type="submit">Send Message</Button>
                </Form> */}
              {/* <div id="zf_div_c3q_oAOtgbrHdKNK91Ljl5pGd2RWjxBKtdAN6fGAvr4"></div> */}

              <iframe
                src="https://forms.zohopublic.com/codyfoster/form/ContactUsFinTechLawFirm/formperma/c3q_oAOtgbrHdKNK91Ljl5pGd2RWjxBKtdAN6fGAvr4?zf_rszfm=1"
                width="100%"
                height="750px"
              />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Secondsec;
