import React from 'react'
import Footer from '../../Components/Footer/Footer'
import Header from '../../Components/Header/Header'
import Intro from './Intro'
import Secondsec from './Secondsec'
import { Helmet } from 'react-helmet'
const index = () => {
  return (
    <>
    <Helmet>
    <title>
    FinTech Law Legal Partnerships | FinTech Law Firm
            </title>
            <meta name="description" content="FinTech Law partners with some of the most respected groups in the financial and FinTech space, including the IAA and NSCP, to ensure the best representation.
"/>
    </Helmet>
        <Header/>
        <React.Fragment>
            <Intro/>
            <Secondsec/>
        </React.Fragment>
        <Footer/>
        </>
    )
}

export default index