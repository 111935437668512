import React, { useRef, useEffect } from "react";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import Secondsec from "./Secondsec";
import { Helmet } from "react-helmet";
import "./Slideshow.css";

const Index = () => {
  return (
    <>
      <div class="support-scrollsnap"></div>

      <Helmet>
        <title>About FinTech Law Firm | Securities Law Firm</title>
        <meta
          name="description"
          content="Learn more about FinTech Law's experienced team of lawyers and legal advisers and how they can help your firm."
        />
      </Helmet>
      <Header />
      <Secondsec />
      <Footer />
    </>
  );
};

export default Index;
