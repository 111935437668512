import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import img from "../../assets/compliance-graphic.png";
import Button from "react-bootstrap/Button";
import "../Home/Home.css";
import "../Contact/contact.css";

const Intro = () => {
  const [eventstitle, setName] = useState("");
  const [description, setDesc] = useState("");
  const [excerpt, setExcerpt] = useState("");
  const [youtubevideolink, setyoutubevideolink] = useState("");
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState("");
  const [date, setDate] = useState("");

  const params = useParams();
  let { slug } = useParams();

  const styles = {
    width: "100%",
  };

  const getTeamDetails = async () => {
    console.warn(params);
    let result = await fetch(
      `${process.env.REACT_APP_BACKEND}/api/update-events/${params.id}`
    );
    result = await result.json();
    setName(result.eventstitle);
    setDesc(result.description);
    setExcerpt(result.excerpt);
    setFileName(result.document);
    // setMetatitle(result.metatitle);
    // setMetadescription(result.metadescription);
    // setTags(result.tags)
    setDate(
      new Date(parseInt(params.id.substring(0, 8), 16) * 1000).toLocaleString()
    ); // Retrieve date from _id field
  };

  useEffect(() => {
    getTeamDetails();
  }, []);

  return (
    <>
      <section className="contactintrosec">
        <Container>
          <Row className="py-5">
            <Col>
              <h1 className=" contact-intro-title">{eventstitle}</h1>
              <p className="text-white text-center">{date}</p>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Intro;
