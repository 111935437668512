import React, { Component } from 'react'
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import Sidebar from '../Components/Sidebar'
import Home from './Home'

export default class index extends Component {
  render() {
    return (
      <React.Fragment>
      <div className='wrapper'>
            <Header/>
            <Sidebar/>
            <Home/>
            <Footer/>
      </div>
      </React.Fragment>
    )
  }
}
