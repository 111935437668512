import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoffee, faFaceMehBlank } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faLinkedin,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { Form, Button } from "react-bootstrap";
import Fintechlogo from "../../assets/FintechLogo.svg";
import JootGradient from "../../assets/icon-joot-gradient.webp";
import Facebook from "../../assets/facebook.svg";
import Linkden from "../../assets/linkden.svg";
import Twitter from "../../assets/twitter.svg";
import "./Footer.css";

const Footer = () => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const [data, setData] = useState([]);

  const fetchData = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND}/api/get-packages`
    );
    const result = await response.json();
    setData(result);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const filteredData = data.map((row) => {
    if (row.packagetitle.toLowerCase().includes("services")) {
      row.packagetitle = row.packagetitle.replace(/services/gi, "");
    }
    return row;
  });

  const firstColumnData = filteredData.slice(0, 5);
  const secondColumnData = filteredData.slice(5, 8);

  return (
    <section className="footer">
      <Container>
        <Row className="py-5">
          <Col>
            <img src={Fintechlogo} />
            <p className="py-3">
              Experts in corporate formation, private and registered funds,
              capital raising, SEC regulations and everything in between.
            </p>
          </Col>
          <Col md={4}>
            <Row>
              <h2>Services</h2>
              <Col>
                <ul>
                  {firstColumnData.map((row, index) => (
                    <li key={index}>
                      <Link to={`/services/${row.slug}`}>{row.menulabel}</Link>
                    </li>
                  ))}
                </ul>
              </Col>
              <Col>
                <ul>
                  {secondColumnData.map((row, index) => (
                    <li key={index}>
                      <Link to={`/services/${row.slug}`}>
                        {row.menulabel === "Intellectual Property Rights"
                          ? "IP Rights"
                          : row.menulabel}
                      </Link>
                    </li>
                  ))}
                </ul>
              </Col>
            </Row>
          </Col>
          <Col>
            <h2>Resources</h2>
            <ul>
              <Link to={"/blog"}>
                <li>Blog</li>
              </Link>
              {/* <Link to={'/events'}>
                <li>Events</li>
              </Link> */}
              <Link to={"/resources/compliance-newsletter"}>
                <li>Newsletter</li>
              </Link>
            </ul>
          </Col>
          <Col>
            <h2>Community</h2>
            <div>
              <a href="https://twitter.com/fintechlawfirm">
                <img src={Twitter} />
              </a>{" "}
              <a href="https://www.facebook.com/fintechlegal">
                <img className="px-3" src={Facebook} />
              </a>
              <a href="https://www.linkedin.com/company/fintech-law-firm  ">
                {" "}
                <img src={Linkden} />
              </a>
              <br />
              <a href="https://joot.io/">
                <img src={JootGradient} className="py-3" />
              </a>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>
              © 2023 FinTech Law -{" "}
              <Link className="text-white" to={"/privacy-policy"}>
                Privacy Policy
              </Link>{" "}
              |{" "}
              <Link className="text-white" to={"/terms-of-service"}>
                Terms Of Service{" "}
              </Link>{" "}
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Footer;
