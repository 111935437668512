import React from 'react'
import Header from '../../Components/Header/Header'
import Secondsec from './SecondrySec'
import Intro from './Intro'
import Thirdsec from './ThirdSec'
import Footer from '../../Components/Footer/Footer'

const index = () => {
  return (
    <>
        <Header/>
            <React.Fragment>
                <Intro/>
                {/* <Secondsec/> */}
                <Thirdsec/>
            </React.Fragment>
        <Footer/>
    
    
    </>

    )
}

export default index