import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from "@material-ui/core";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

import ModeEditOutlineTwoToneIcon from "@mui/icons-material/ModeEditOutlineTwoTone";

import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import "../../index.css";
import { request } from "../../../helpers/fetch-wrapper";

const Data = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const fetchData = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND}/api/get-webinars`
    );
    const result = await response.json();
    setData(result);
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const DeletePartnership = async (id) => {
    await request({
      url: `${process.env.REACT_APP_BACKEND}/api/delete-webinars/${id}`,
      method: "delete",
    });
    fetchData();
  };
  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0"> Webinars List</h1>
            </div>
            {/* /.col */}
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <Link to="/new-webinars">
                  <Button variant="primary" size="lg">
                    Add New
                  </Button>
                </Link>
              </ol>
            </div>
            {/* /.col */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
      </div>
      <div className="mx-3">
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>S.no #</TableCell>
                <TableCell>Featured Image</TableCell>
                <TableCell>Webinar Title</TableCell>
                <TableCell>Excerpt</TableCell>
                <TableCell>Youtube Playlist Link</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <TableRow key={row._id}>
                    <TableCell>{index + 1 + page * rowsPerPage}</TableCell>
                    <TableCell>
                      <img
                        className="table-backend-img"
                        src={
                          "https://storage.googleapis.com/fintechlegal/posts/" +
                          row.document
                        }
                      />
                    </TableCell>
                    <TableCell>{row.eventstitle}</TableCell>
                    <TableCell>{row.excerpt}</TableCell>
                    <TableCell>
                      <b>{row.youtubevideolink}</b>
                    </TableCell>

                    <TableCell>
                      <DeleteTwoToneIcon
                        onClick={() => DeletePartnership(row._id)}
                      ></DeleteTwoToneIcon>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </div>
    </div>
  );
};

export default Data;
