import React, { useState, useEffect } from 'react';
import { Container, Row,Col } from 'react-bootstrap'
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import Team1 from '../../assets/Bob.png'
import Team2 from '../../assets/Tatiana-Filippova-framed.png'
const Fifthsec = () => {
    const [data, setData] = useState([]);

    const fetchData = async () => {
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/get-homepage`);
        const result = await response.json();
        setData(result);
      };
      useEffect(() => {
        fetchData();
      }, []);
      const styles ={
        width: '100%',
      }
  return (
    <section className='fifth-sec'>
        <Container>
        {data.map((row, index) => (
<>
            <Row>
              
               <Col md={4}> <img src={Team1} style={styles}/></Col>
               <Col md={8}>

                    <h1>{row.headingthree}</h1>
                    <p>{row.ctatitleheading}</p>
                </Col>
            </Row>
            <Row className='py-5'>
            <Col md={8}>
                    <h1>{row.ctadesc}</h1>
                    <p>{row.ctabutton}
</p>
                                       </Col>
               <Col md={4}> <img className='home-sec-Team2img' src={Team2} style={styles}/></Col>
               
            </Row>
            </>
        ))}
        <Link to={'/contact'} className='nounderline'>
<Button variant="outline-light my-5" className="blue-btn home-contact-btn">Contact FinTech Law</Button></Link>
        </Container>
    </section>

    )
}

export default Fifthsec
