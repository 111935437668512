import React from 'react'
import Header from '../../Components/Header/Header'
import Secondsec from './Secondsec'
import Intro from './Intro'
import Thirdsec from './Thirdsec'
import Footer from '../../Components/Footer/Footer'
import FourthSec from './FourthSec'

const index = () => {
  return (
    <>
        <Header/>
            <React.Fragment>
                <Intro/>
                <Secondsec/>
                {/* <Thirdsec/> */}
                <FourthSec/>
            </React.Fragment>
        <Footer/>
    
    
    </>

    )
}

export default index