import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TextField, Button, Grid, makeStyles } from "@material-ui/core";
import "../../index.css";
import { request } from "../../../helpers/fetch-wrapper";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "100%",
    },
  },
}));

const UpdateCollaborators = () => {
  const classes = useStyles();
  const [fname, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [invalidEmailMessage, setInvalidEmailMessage] = useState("");

  const params = useParams();
  const navigate = useNavigate();

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);

    // Basic email validation using a regular expression
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailPattern.test(newEmail);
    setIsValidEmail(isValid);
    setInvalidEmailMessage("Enter a valid email address.");
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    updatecollaborator();
  };

  useEffect(() => {
    getCollaboratorDetails();
  }, []);

  const getCollaboratorDetails = async () => {
    console.warn(params);
    const result = await request({
      method: "get",
      url: `${process.env.REACT_APP_BACKEND}/api/get-collaborator/${params.id}`,
    });

    setName(result.fname);
    setEmail(result.email);
    setPassword(result.password);
  };

  const updatecollaborator = async () => {
    const baseUrl = `${process.env.REACT_APP_BACKEND}/api/update-collaborator/${params.id}`;
    const data = {
      fname: fname,
      email: email,
      password: password,
      userID: params.id,
    };
    const formData = new FormData();
    for (let name in data) {
      if (data[name]) formData.append(name, data[name]);
    }

    try {
      const result = await request({
        method: "put",
        url: baseUrl,
        data: formData,
      });

      if (result) {
        navigate("/collaborators");
      }
    } catch (error) {
      if (error.response.status === 400) {
        setIsValidEmail(false);
        setInvalidEmailMessage(
          "Collaborator with this email already exists. Choose another email."
        );
      } else {
        console.error("Error submitting collaborator:", error);
        navigate("/collaborators");
      }
    }
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Update Collaborator</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right"></ol>
              </div>
            </div>
          </div>
        </div>

        <div className="adminform-background px-3 mx-3 py-3">
          <form className={classes.root} onSubmit={handleSubmit}>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <TextField
                  required
                  label="Collaborator Name"
                  value={fname}
                  onChange={handleNameChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  required
                  label="Collaborator Email"
                  value={email}
                  onChange={handleEmailChange}
                  error={!isValidEmail}
                  helperText={!isValidEmail ? invalidEmailMessage : ""}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Collaborator Password"
                  value={password}
                  onChange={handlePasswordChange}
                />
              </Grid>

              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={!isValidEmail}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </div>
    </>
  );
};

export default UpdateCollaborators;
