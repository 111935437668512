import React from "react";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import Intro from "./Intro";
import { Helmet } from "react-helmet";
import FlipCards from "../../Dashboard/Components/FlipCards";
import SubscribeForm from "../../Dashboard/Components/SubscribeForm";

const index = () => {
  return (
    <>
      <Helmet>
        <title>Legal Compliance Newsletter | FinTech Law Firm</title>
        <meta
          name="description"
          content="Follow FinTech Law Firm and our SEC compliance partner Joot's quarterly legal and compliance newsletter for industry updates and insights.
"
        />
      </Helmet>
      <Header />
      <React.Fragment>
        <Intro />
        <FlipCards
          apiLink={`${process.env.REACT_APP_BACKEND}/api/get-newsletterresources`}
          downloadBaseUrl={`${process.env.REACT_APP_BACKEND}/api/download-newsletter`}
        />
        <SubscribeForm />
      </React.Fragment>
      <Footer />
    </>
  );
};

export default index;
