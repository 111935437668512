import React,{useEffect,useState} from 'react'
import { Container, Row , Col } from 'react-bootstrap'
import { Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';


const FourthSec = () => {

    const [partner, setpartner] = useState([]);

    const fetchpartnerpage = async () => {
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/get-eventspage`);
        const result = await response.json();
        setpartner(result);
      };
      useEffect(() => {
          fetchpartnerpage();
      }, []);

  return (
    <section className='fourth-sec'>
    <Container>
        <Row>
            <Col>
            <Form >
                <Row>
                    <Col>
                        <Form.Group controlId="formEmail">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control type="email" placeholder="Enter your email" style={{ height: '50px' }} />
                        </Form.Group>
                        </Col>
                        <Col>
                        <Form.Group controlId="formEmail">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control type="email" placeholder="Enter your email" style={{ height: '50px' }} />
                        </Form.Group>
                        </Col>
                        <Col>
                        <Form.Group controlId="formEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" placeholder="Enter your email" style={{ height: '50px' }} />
                        </Form.Group>
                        </Col>
                        </Row>
                        <Button variant="primary" type="submit" className='my-3  newsletter-btn'>
                            Newsletter Signup
                        </Button>
                      </Form>
        </Col>
        </Row>
    </Container>
</section>

    )
}

export default FourthSec