import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import {
  TextField,
  Button,
  Grid,
  makeStyles,
  InputLabel,
} from "@material-ui/core";
import { request } from "../../helpers/fetch-wrapper";

const AdditionalResource = () => {
  const [additionalresourcestitle, setadditionalresourcestitle] = useState("");
  const [additionalresourcesdesc, setadditionalresourcesdesc] = useState("");
  const [additionalresourcesbutton, setadditionalresourcesbutton] =
    useState("");

  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    submitProduct(); // Call the submitProduct function here
  };

  const submitProduct = async () => {
    const baseUrl = `${process.env.REACT_APP_BACKEND}/api/add-additionalresources`;
    const data = {
      additionalresourcestitle,
      additionalresourcesdesc,
      additionalresourcesbutton,
    };

    const result = await request({
      method: "post",
      url: baseUrl,
      data: data,
    });

    if (result) {
      console.log(result);
      navigate("/additionalresources-list");
    }

    // replace with actual submission logic
  };

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Add New Additional Resource</h1>
            </div>
            {/* /.col */}
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right"></ol>
            </div>
            {/* /.col */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
      </div>
      <div className="adminform-background px-3 mx-3 py-3">
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                required
                label="Title"
                value={additionalresourcestitle}
                onChange={(event) =>
                  setadditionalresourcestitle(event.target.value)
                }
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <InputLabel htmlFor="name-input">Description</InputLabel>

              <Editor
                apiKey={process.env.REACT_APP_TINYMCE_KEY}
                textareaName="product Description"
                value={additionalresourcesdesc}
                //onEditorChange={(e)=>SetDescription(e.target.value)}
                onEditorChange={(newValue, editor) =>
                  setadditionalresourcesdesc(newValue)
                }
                init={{
                  height: 500,
                  menubar: false,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | " +
                    "bold italic backcolor | alignleft aligncenter " +
                    "alignright alignjustify | bullist numlist outdent indent | " +
                    "removeformat | help",
                  content_style:
                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                required
                label="button link"
                value={additionalresourcesbutton}
                onChange={(event) =>
                  setadditionalresourcesbutton(event.target.value)
                }
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
};

export default AdditionalResource;
