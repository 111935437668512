import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import b1 from "../../assets/b1.webp";
import b2 from "../../assets/b2.webp";
import user from "../../assets/Cody_Foster.webp";
import "./Home.css";
const Blogsec = () => {
  const [data, setData] = useState([]);

  const fetchData = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND}/api/get-posts`
    );
    const result = await response.json();

    setData(result);
  };
  useEffect(() => {
    fetchData();
  }, []);

  // Sort data array by date in descending order (newest to oldest)
  const sortedData = [...data].sort(
    (a, b) => new Date(b.date) - new Date(a.date)
  );

  return (
    <section className="blog-sec">
      <Container>
        <Row className="py-3">
          <Col md={3}>
            <h1>The Latest: FinTech Law Blog</h1>
            <p>
              Articles to break down the legalese and help educate you about
              fintech
            </p>
            <Link to="/blog">
              <Button
                className="blue-btn home-contact-btn btn btn-outline-light my-5"
                variant="outline-light"
              >
                Read the Fintech Law Blog
              </Button>
            </Link>
          </Col>
          {sortedData.slice(0, 3).map((row, index) => (
            <Col md={3} className="blog1stcol">
              <Link to={"/blog/" + row.slug} className="blog-link">
                <Card className="blog-box">
                  <Card.Img
                    variant="top"
                    src={
                      "https://storage.googleapis.com/fintechlegal/posts/" +
                      row.document
                    }
                  />
                  <Card.Body className="blog-body">
                    {/* <p className='blog-date'>{new Date(parseInt(row._id.substring(0,8), 16) * 1000).toLocaleDateString()}</p> */}
                    <Card.Title className="blog-title">
                      {row.posttitle}
                    </Card.Title>
                    {/* <Card.Text className='blog-exerpt'>
                                    {row.excerpt}
                                </Card.Text> */}
                    {/* <Row>
                                    <Col md={1}>
                                        <img className='blogu-icon' src={user}/>
                                    </Col>
                                    <Col md={11}>
                                        <h6 className='blog-author'>Cody Foster</h6>
                                        <p>Digital Marketing Manager, Joot</p>
                                    </Col>
                                </Row> */}
                    {/* <Row>
                                    <Col className='blog-tag-col' >
                                        {row.tags.split(',').map(tag => (
                                            <div key={tag}>
                                                <Button variant="outline-light" className="blogtag-btn">{tag.trim()}</Button>
                                            </div>
                                        ))}
                                    </Col>
                                </Row> */}
                  </Card.Body>
                </Card>
              </Link>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default Blogsec;
