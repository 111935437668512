import React,{useEffect,useState} from 'react'
import { Container, Row , Col , Form} from 'react-bootstrap'
import Button from 'react-bootstrap/Button';


const Secondsec = () => {
    
    const [partner, setpartner] = useState([]);

    const fetchpartnerpage = async () => {
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/get-partnerpage`);
        const result = await response.json();
        setpartner(result);
      };
      useEffect(() => {
          fetchpartnerpage();
      }, []);

  return (
//     <section className='fourth-sec'>
//         <Container fluid>
//             <Row>
//                 <Col>
//                 {partner.map((row, index) => (
// <>
//                     <h1 className='refunds-h1'>{row.ctatitleheading}</h1>
//                     <p className='refunds-p'>{row.ctasubheading}</p>
//                     </>
//                 ))}
//                 </Col>
//             </Row>
//         </Container>
//     </section>
<section className='fourth-sec'>
<Container>
    <Row>
        <Col>
        {/* <Form >
            <Row>
                <Col>
                    <Form.Group controlId="formEmail">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control type="email" placeholder="Enter your email" style={{ height: '50px' }} />
                    </Form.Group>
                    </Col>
                    <Col>
                    <Form.Group controlId="formEmail">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control type="email" placeholder="Enter your email" style={{ height: '50px' }} />
                    </Form.Group>
                    </Col>
                    <Col>
                    <Form.Group controlId="formEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" placeholder="Enter your email" style={{ height: '50px' }} />
                    </Form.Group>
                    </Col>
                    </Row>
                    <Button variant="primary" type="submit" className='my-3  newsletter-btn'>
                        Newsletter Signup
                    </Button>
                  </Form> */}

<iframe src='https://forms.zohopublic.com/codyfoster/form/ComplianceUpdatesFTL/formperma/ra3WF4tQLb1XkKAxk3fkkEgt5A2zN4AqV5Qhp_V3mEo?zf_rszfm=1' width="100%" height="450px" />

    </Col>
    </Row>
</Container>
</section>
    )
}

export default Secondsec