import React ,{useState,useEffect}from 'react'
import { useParams } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap'
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import './ReFunds.css'

const Intro = () => {
  const [packagetitle, setHeading] = useState("");
  const [packagedesc, setDesc] = useState("");
  const [packageexcerpt, setPackageexcerpt] = useState("");
  const [packagesubdescone, setPackagesubdescone] = useState("");
  const [packagedesctitle, setPackagedesctitle] = useState("");
  const [packagesubdesctwo, setPackagesubdesctwo] = useState("");
  const [packagesubdescthree, setPackagesubdescthree] = useState("");  
  const [metatitle, setMetatitle] = useState('');
  const [metadescription, setMetadescription] = useState('');
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState("");
  const [error, SetError]=useState("");
  const params = useParams();
  let { slug } = useParams();

  const getTeamDetails = async () => {
    console.warn(params);
    let result = await fetch(`${process.env.REACT_APP_BACKEND}/api/update-package/${params.slug}`);
    result = await result.json();
    setHeading(result.packagetitle);
    setDesc(result.packagedesc);
    setPackageexcerpt(result.packageexcerpt);
    setPackagesubdescone(result.packagesubdescone);
    setPackagedesctitle(result.setPackagedesctitle);
    setPackagesubdesctwo(result.packagesubdesctwo);
    setPackagesubdescthree(result.packagesubdescthree);

    setFileName(result.document)
    setMetatitle(result.metatitle);
    setMetadescription(result.metadescription);
    // setDate(new Date(parseInt(params.id.substring(0, 8), 16) * 1000).toLocaleString()); // Retrieve date from _id field
  };

  useEffect(() => {
    getTeamDetails();
  }, []);

    // const [partner, setpartner] = useState([]);

    // const fetchpartnerpage = async () => {
    //     const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/get-registeredfunds`);
    //     const result = await response.json();
    //     setpartner(result);
    //   };
    //   useEffect(() => {
    //       fetchpartnerpage();
    //   }, []);
  return (
    <section className="blogintrosec">
        <Container >
          <Row className='py-5'>
            <Col>

<>
    <h2 className='inner-service-head'>{packagetitle}</h2>
    <h1 className='inner-service-subhead'>{packageexcerpt}</h1>
   
        {/* <Link to={row.buttonone}>
    <Button variant="outline-light my-5" className="blue-btn refundbtn">GET COMPLIANCE DONE RIGHT</Button></Link> */}
</>
         
            </Col>
           
            
          </Row>
      
        </Container>
      </section>
       

    )
}

export default Intro