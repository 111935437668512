import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ReactHtmlParser from "react-html-parser";
import { Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoffee, faFaceMehBlank } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faLinkedin,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import Card from "react-bootstrap/Card";
import "./blogdetail.css";
import user from "../../assets/Cody_Foster.webp";

const Secondsec = () => {
  const [eventstitle, setName] = useState("");
  const [description, setDesc] = useState("");
  const [excerpt, setExcerpt] = useState("");
  const [youtubevideolink, setyoutubevideolink] = useState("");
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState("");
  const [date, setDate] = useState("");

  const params = useParams();
  let { slug } = useParams();

  const styles = {
    width: "100%",
  };

  const getTeamDetails = async () => {
    console.warn(params);
    let result = await fetch(
      `${process.env.REACT_APP_BACKEND}/api/update-events/${params.id}`
    );
    result = await result.json();
    setName(result.eventstitle);
    setDesc(result.description);
    setExcerpt(result.excerpt);
    setFileName(result.document);
    // setMetatitle(result.metatitle);
    // setMetadescription(result.metadescription);
    // setTags(result.tags)
    setDate(
      new Date(parseInt(params.id.substring(0, 8), 16) * 1000).toLocaleString()
    ); // Retrieve date from _id field
  };

  useEffect(() => {
    getTeamDetails();
  }, []);

  // const [data, setData] = useState([]);

  //   const fetchData = async () => {
  //       const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/get-posts`);
  //       const result = await response.json();
  //       setData(result);
  //     };
  //     useEffect(() => {
  //       fetchData();
  //     }, []);

  return (
    <section className="blog-detail-secod-sec">
      <Container>
        {/* <title>{metatitle}</title>
<meta name="description" content={metadescription}/> */}
        <img
          style={styles}
          src={"https://storage.googleapis.com/fintechlegal/posts/" + fileName}
        />

        <Row>
          <Col className="">
            {/* <h1>{posttitle}</h1> */}
            <p className="blog-date">{date}</p>
            <p className="blog-inner-detail-desc">
              {ReactHtmlParser(description)}
            </p>
            {/* <Row className='py-5'>
            <h2 className='py-3'>Author</h2>
                <Col md={1}>
                    <img className='blogu-icon-details' src={user}/>
                </Col>
                <Col md={11} className="px-5">
                    <h6 className='blog-author'>Cody Foster</h6>
                    <p>Digital Marketing Manager, Joot</p>
                </Col>
            </Row> */}
            {/* <h2>Topics</h2>
            <Button variant="outline-light" className="blogtag-btn">{tags}</Button> */}

            <Row className="py-5 article-share-icons">
              <h2 className="py-3">Share this Event</h2>
              <Col>
                <FontAwesomeIcon icon={faFacebook} size="3x" className="px-2" />
                <FontAwesomeIcon icon={faLinkedin} size="3x" className="px-2" />
                <FontAwesomeIcon icon={faTwitter} size="3x" className="px-2" />
                <br />
                <Link to={"/events"} className="nounderline">
                  <Button>View More Events</Button>
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Secondsec;

{
  /* <Col className="blog-inner-detail-right-col" md={4}>
            <h2>Recent Posts</h2>


            {data.map((row, index) => (
  <Link to={"/blog/"+row.slug} className="blog-link" target='_blank'>
    <Row className='blog1stcol'>
      <Card className='blog-box'>
        <Card.Img variant="top" src={`${process.env.REACT_APP_BACKEND}/uploads/blogs/${row.document}`} />
        <Card.Body className='blog-body'>
          <p className='blog-date'>{new Date(parseInt(row._id.substring(0,8), 16) * 1000).toLocaleDateString()}</p>
          <Card.Title className='blog-title'>{row.posttitle}</Card.Title>
          <Card.Text className='blog-exerpt'>
            {row.excerpt}
          </Card.Text>
        </Card.Body>
      </Card>
    </Row>
  </Link>
))}


          </Col> */
}
