import React, { useState, useEffect } from 'react';
import { Container, Row,Col } from 'react-bootstrap'
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';


const Fourthsec = () => {
    const [data, setData] = useState([]);

    const fetchData = async () => {
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/get-homepage`);
        const result = await response.json();
        setData(result);
      };
      useEffect(() => {
        fetchData();
      }, []);
  return (
    <section className='fourth-sec'>
        <Container>
            <Row>
            {data.map((row, index) => (
<>

                <Col md={8}>
                    
                    <p>{row.userreviewheading}
</p>
                </Col>
                <Col>
                <Link to={row.userreviewposition}>
                <Button>Contact FinTech Law</Button></Link>
</Col>
</>
            ))}
            </Row>
        </Container>
    </section>

    )
}

export default Fourthsec

{/* <section className='fourth-sec'>
        <Container fluid>
            <Row>
                <Col>
                {data.map((row, index) => (
                    <>
                <h1>{row.ctatitleheading}</h1>
                <h4 className='py-4'>{row.ctadesc}</h4>
                <Link to={row.ctabutton}>
                <Button variant="outline-light" className="blue-btn">GET IN TOUCH</Button></Link>
                </>
                ))}
                </Col>
            </Row>
        </Container>
    </section> */}