import React, {useState,useEffect} from 'react'
import { Container, Row,Col } from 'react-bootstrap'
import Button from 'react-bootstrap/Button';
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Fre from '../../assets/featured-resource-eq.webp'

const Thirdsec = () => {
    const [partner, setpartner] = useState([]);

    const fetchpartnerpage = async () => {
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/get-reourcespage`);
        const result = await response.json();
        setpartner(result);
      };
      useEffect(() => {
          fetchpartnerpage();
      }, []);
      const styles = {
        width:'100%'
      }
  return (
    <>
    <section  className='featured-resource-sec'>
        <Container>
        <Row>
            <Col md={7}>
            <h1>
            Featured Resource
  
            </h1>
            <p>

            Need help navigating the new SEC Derivatives Rule? We can<br/> help you implement an effective derivatives risk management<br/> program and maintain compliance.


            </p>
            <Link to={'/blog'}>
            <Button>Read More</Button></Link>
            </Col>
            <Col md={5}>
            <img src={Fre} style={styles}/>
            </Col>
        </Row>
        </Container>
    </section>
    <section className='fourth-sec'>
    <Container>
        <Row>
            <Col>
            <Form >
                <Row>
                    <Col>
                        <Form.Group controlId="formEmail">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control type="email" placeholder="Enter your email" style={{ height: '50px' }} />
                        </Form.Group>
                        </Col>
                        <Col>
                        <Form.Group controlId="formEmail">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control type="email" placeholder="Enter your email" style={{ height: '50px' }} />
                        </Form.Group>
                        </Col>
                        <Col>
                        <Form.Group controlId="formEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" placeholder="Enter your email" style={{ height: '50px' }} />
                        </Form.Group>
                        </Col>
                        </Row>
                        <Button variant="primary" type="submit" className='my-3  newsletter-btn'>
                            Newsletter Signup
                        </Button>
                      </Form>
        </Col>
        </Row>
    </Container>
</section>
</>

    )
}

export default Thirdsec