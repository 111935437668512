import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { TextField, Button, Grid, makeStyles } from "@material-ui/core";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import "../index.css";
import { request } from "../../helpers/fetch-wrapper";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "100%",
    },
  },
}));

const NewCollaborator = () => {
  const classes = useStyles();
  const [fname, setFname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [invalidEmailMessage, setInvalidEmailMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  const handleFnameChange = (event) => {
    setFname(event.target.value);
  };

  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);

    // Basic email validation using a regular expression
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailPattern.test(newEmail);
    setIsValidEmail(isValid);
    setInvalidEmailMessage("Enter a valid email address.");
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    submitCollaborator();
  };

  const submitCollaborator = async () => {
    const baseUrl = `${process.env.REACT_APP_BACKEND}/api/add-collaborator`;
    const data = {
      fname: fname,
      email: email,
      password: password,
    };
    const formData = new FormData();
    for (let name in data) {
      formData.append(name, data[name]);
    }

    try {
      const result = await request({
        method: "post",
        url: baseUrl,
        data: formData,
      });

      if (result) {
        navigate("/collaborators");
      }
    } catch (error) {
      if (error.response.status === 400) {
        setIsValidEmail(false);
        setInvalidEmailMessage(
          "Collaborator with this email already exists. Choose another email."
        );
      } else {
        console.error("Error submitting collaborator:", error);
        navigate("/collaborators");
      }
    }
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Add Collaborator</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right"></ol>
              </div>
            </div>
          </div>
        </div>

        <div className="adminform-background px-3 mx-3 py-3">
          <form className={classes.root} onSubmit={handleSubmit}>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <TextField
                  required
                  label="Collaborator Name"
                  value={fname}
                  onChange={handleFnameChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  required
                  label="Collaborator Email"
                  value={email}
                  onChange={handleEmailChange}
                  error={!isValidEmail}
                  autoComplete="off"
                  helperText={!isValidEmail ? invalidEmailMessage : ""}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  required
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  autoComplete="off"
                  onChange={handlePasswordChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleTogglePasswordVisibility}
                          edge="end"
                        >
                          {showPassword ? (
                            <VisibilityOffIcon />
                          ) : (
                            <VisibilityIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={!isValidEmail}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </div>
    </>
  );
};

export default NewCollaborator;
