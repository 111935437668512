import React, { useState, Img } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./Slideshow.css";
import Slideshow from "./Slideshow";

const Intro = () => {
  const images = [
    {
      image:
        "https://storage.googleapis.com/fintechlegal/posts/fintech_AI3.png",
    },
    {
      image:
        "https://storage.googleapis.com/fintechlegal/posts/fintech_AI1.png",
    },
  ];

  return (
    <>
      <section className="secondsec-text blog-sec">
        <Container>
          <Row
            className="py-6"
            style={{ minHeight: "450px", background: "rgba(10, 5, 5, 0.7)" }}
          >
            <Col md={6} className="example-images">
              <img
                src={images[0].image}
                className="img-fluid w-100"
                style={{
                  minHeight: "280px",
                  minWidth: "280px",
                  maxHeight: "500px",
                  maxWidth: "500px",
                  border: "none",
                }}
              />
            </Col>
            <Col
              md={6}
              className="d-md-flex flex-md-column justify-content-center"
            >
              <p>
                The FinTech Law Assistant, powered by ChatGPT, changes how
                professionals navigate federal securities law's complexities.
                Available 24/7, it offers instant answers and comprehensive
                coverage on topics ranging from RIA to RIC transitions and
                startup queries.
              </p>
              <p>
                This AI tool ensures seamless legal guidance at your fingertips,
                making securities regulations more accessible and manageable for
                everyone in the FinTech space.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <Slideshow />
      <section className="secondsec-text blog-sec">
        <Container>
          <Row
            className="py-6"
            style={{ minHeight: "450px", background: "rgba(10, 5, 5, 0.7)" }}
          >
            <Col md={6} className="example-images">
              <img
                src={images[1].image}
                className="img-fluid w-100"
                style={{
                  minHeight: "280px",
                  minWidth: "280px",
                  maxHeight: "500px",
                  maxWidth: "500px",
                  border: "none",
                }}
              />
            </Col>
            <Col
              md={6}
              className="d-md-flex flex-md-column justify-content-center"
            >
              <h1>What Is ChatGPT?</h1>
              <p>
                ChatGPT, developed by OpenAI, is a game-changer in the FinTech
                sector. It elevates customer experiences, streamlines
                decision-making, and enhances operational efficiency. With its
                real-time data analysis capabilities, ChatGPT increases
                productivity, drives innovation, and aids in creating more
                secure, customer-oriented financial services.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Intro;
