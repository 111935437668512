import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import {
  TextField,
  Button,
  Grid,
  makeStyles,
  InputLabel,
} from "@material-ui/core";
import { Alert } from "@mui/material";
import slugify from "slugify";
import { request } from "../../helpers/fetch-wrapper";

const NewPackage = () => {
  const [packagetitle, setHeading] = useState("");
  const [packagedesc, setDesc] = useState("");
  const [packageexcerpt, setPackageexcerpt] = useState("");
  const [packagesubdescone, setPackagesubdescone] = useState("");
  const [packagedesctitle, setPackagedesctitle] = useState("");
  const [packagesubdesctwo, setPackagesubdesctwo] = useState("");
  const [packagesubdescthree, setPackagesubdescthree] = useState("");
  const [metatitle, setMetatitle] = useState("");
  const [metadescription, setMetadescription] = useState("");

  const [buttontext, setButtontext] = useState("");
  const [buttonlink, setButtonlink] = useState("");
  const [menulabel, setMenulabel] = useState("");

  const [file, setFile] = useState();
  const [fileName, setFileName] = useState("");
  const [error, setError] = useState("");
  const [slug, setSlug] = useState("");

  const navigate = useNavigate();

  const handleNameChange = (event) => {
    setHeading(event.target.value);
    setSlug(slugify(event.target.value, { lower: true }));
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    submitProduct(); // Call the submitProduct function here
  };

  const submitProduct = async () => {
    const baseUrl = `${process.env.REACT_APP_BACKEND}/api/add-packages`;
    const data = {
      packagetitle: packagetitle,
      packagedesc: packagedesc,
      packageexcerpt: packageexcerpt,
      packagesubdescone: packagesubdescone,
      packagedesctitle: packagedesctitle,
      packagesubdesctwo: packagesubdesctwo,
      packagesubdescthree: packagesubdescthree,
      slug: slug,
      metatitle: metatitle,
      metadescription: metadescription,
      buttontext: buttontext,
      buttonlink: buttonlink,
      menulabel: menulabel,
    };
    const formData = new FormData();
    for (let name in data) {
      formData.append(name, data[name]);
    }
    formData.append("file", file);
    if (!file) {
      setError("Please upload an image.");
      return;
    } else {
      setError("");
    }
    const result = await request({
      method: "post",
      url: baseUrl,
      data: formData,
    });

    if (result) {
      console.log(result);
      navigate("/packages-list");
    }
  };

  const saveFile = (e) => {
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Add New Services</h1>
            </div>
            {/* /.col */}
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right"></ol>
            </div>
            {/* /.col */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
      </div>
      <div className="adminform-background px-3 mx-3 py-3">
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                required
                label="Heading"
                value={packagetitle}
                onChange={handleNameChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                label="Excerpt"
                value={packageexcerpt}
                onChange={(e) => setPackageexcerpt(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                label="Description one"
                value={packagesubdescone}
                onChange={(e) => setPackagesubdescone(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                label="Description Title"
                value={packagedesctitle}
                onChange={(e) => setPackagedesctitle(e.target.value)}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <InputLabel htmlFor="name-input">Description</InputLabel>

              <Editor
                apiKey={process.env.REACT_APP_TINYMCE_KEY}
                textareaName="product Description"
                value={packagedesc}
                //onEditorChange={(e)=>SetDescription(e.target.value)}
                onEditorChange={(newValue, editor) => setDesc(newValue)}
                init={{
                  height: 500,
                  menubar: true,
                  plugins:
                    "autolink lists link image charmap print preview anchor searchreplace visualblocks code fullscreen insertdatetime media table paste code help wordcount",

                  toolbar:
                    "undo redo | formatselect | " +
                    "bold italic backcolor | alignleft aligncenter " +
                    "alignright alignjustify | bullist numlist outdent indent | " +
                    "removeformat | help",
                  content_style:
                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                label="Description two"
                value={packagesubdesctwo}
                onChange={(e) => setPackagesubdesctwo(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                label="Description Three"
                value={packagesubdescthree}
                onChange={(e) => setPackagesubdescthree(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <input
                accept="image/*"
                id="thumbnail-upload"
                type="file"
                onChange={saveFile}
                style={{ display: "none" }}
              />
              {fileName && (
                <div className="img-box-dashboard-featured">
                  <img
                    src={URL.createObjectURL(file)}
                    alt="Selected file preview"
                  />
                </div>
              )}
              <label htmlFor="thumbnail-upload">
                <Button variant="contained" color="primary" component="span">
                  Upload Resource
                </Button>
              </label>
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                label="Button Text"
                value={buttontext}
                onChange={(e) => setButtontext(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                label="Button Link"
                value={buttonlink}
                onChange={(e) => setButtonlink(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                label="Menu Label"
                value={menulabel}
                onChange={(e) => setMenulabel(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                label="Meta Title"
                value={metatitle}
                onChange={(e) => setMetatitle(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                label="Meta Description"
                value={metadescription}
                onChange={(e) => setMetadescription(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              {error && (
                <Alert severity="error" onClose={() => setError("")}>
                  {error}
                </Alert>
              )}
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
};

export default NewPackage;
